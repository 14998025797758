import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { VideoService } from '../../services/video.service';
import { Corso } from 'src/app/models/video';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-corsi',
  templateUrl: './corsi.component.html',
  styleUrls: ['./corsi.component.scss']
})
export class CorsiComponent implements OnInit {
  load: boolean = false;
  videos: any[] = [];
  description: string = '';
  searchText: string = '';
  videoDaVisualizzare: string | null = null;
  title: string = '';
  corsi: Corso[] = [];
  isCollapsed: boolean[] = [];
  isMenuCollapsed: boolean = true;

  constructor(
    private readonly videoService: VideoService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.showLoading();
    this.videoService.getCorsi().subscribe(
      (corsi) => this.handleMenu(corsi),
      (error) => {
        console.error('Errore durante il caricamento dei corsi:', error);
        Swal.fire('Errore', 'Non è stato possibile caricare i corsi.', 'error');
      }
    );
  }

  onVideoSelect(video: any): void {
    this.videoDaVisualizzare = video.player_embed_url;
    this.title = video.name;
    this.description = video.description;
  }

  handleTime(sec: number): string {
    const date = new Date(0);
    date.setSeconds(sec);
    return date.toISOString().substr(14, 5);
  }

  private handleMenu(corsi: Corso[]): void {
    this.corsi = corsi;
    this.isCollapsed = new Array(this.corsi.length).fill(false);
    this.isCollapsed[0] = true;

    if (this.corsi[1]?.video?.length > 0) {
      this.onVideoSelect(this.corsi[1].video[1]);
    }

    Swal.close();
  }

  toggleMenu(): void {
    this.isMenuCollapsed = !this.isMenuCollapsed;
  }

  private showLoading(): void {
    Swal.fire({
      title: 'Caricamento in corso...',
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }
}
