<header class="gym loading-header nav-abs custom-scroll" style="background-color: black;">
  <div class="container">
    <div class="row">
      <div class="col">
        <nav>
          <a href="#" class="d-inline-block m-r-auto">
            <img src="assets/images/logo/123 copia.png" alt="Logo" style="max-width: 150px;" class="img-fluid" />
          </a>
          <app-menu></app-menu>
        </nav>
      </div>
    </div>
  </div>
</header>

<section class="gym format">
  <button class="navbar-toggler d-md-none" (click)="toggleMenu()" style="color: white; z-index: 1050; left: 15px;">
    <span style="color: #ec5c15;">
      <i class="fa fa-bars" aria-hidden="true"></i> Video
    </span>
  </button>

  <div class="container-fluid">
    <div class="row">
      <div *ngIf="!isMenuCollapsed" class="menu-overlay" (click)="toggleMenu()"></div>

      <div [ngClass]="{ 'menu-mobile': true, 'show': !isMenuCollapsed }" class="col-md-3">
        <input [(ngModel)]="searchText" type="text" class="form-control d-none d-md-block mt-2" placeholder="Cerca video..." />
        <div *ngIf="corsi.length > 0" class="mt-2">
          <ngb-accordion *ngFor="let corso of corsi; let i = index" #acc="ngbAccordion" activeIds="panel1" [closeOthers]="true">
            <ng-container *ngIf="corso?.gruppo !== 'Base'">
              <ngb-panel id="panel{{ i }}">
                <ng-template ngbPanelTitle>
                  <span>{{ corso.gruppo }}</span>
                </ng-template>
                <ng-template ngbPanelContent>
                  <ul class="list-group">
                    <li *ngFor="let video of corso.video | filterVideo: searchText" class="list-group-item" (click)="onVideoSelect(video)">
                      <i class="bi bi-play-circle-fill"></i> {{ video.name }} ({{ handleTime(video.duration) }})
                    </li>
                  </ul>
                </ng-template>
              </ngb-panel>
            </ng-container>
          </ngb-accordion>
        </div>
      </div>

      <div class="col-md-9">
        <div *ngIf="videoDaVisualizzare; else noVideo">
          <div class="format-head-text">
            <h3 class="about-font-header gradient-text mt-4" style="color: #ec5c15;">{{ title }}</h3>
          </div>
          <div style="padding-bottom: 56.25%; position: relative; display: block; width: 100%; margin-top: 30px;">
            <iframe
              width="100%"
              height="100%"
              [src]="sanitizer.bypassSecurityTrustResourceUrl(videoDaVisualizzare)"
              frameborder="0"
              allowfullscreen
              style="position: absolute; top: 0; left: 0;">
            </iframe>
          </div>
          <div class="format-sub-text mt-5">
            <p class="p-light about-para">{{ description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #noVideo>
    <div style="text-align: center; padding: 20px;">
      Nessun video selezionato
    </div>
  </ng-template>
</section>

<app-gym-footer></app-gym-footer>
<app-gym-copyright></app-gym-copyright>
